import apiService from "./apiService"

const AuthServices = {
    getProfile: async (uid = ':uid') => {
        try {
            const { data } = await apiService.get(`/user-profile/${uid}`)
            return data
        } catch (error) {
            if (error?.data?.includes?.('not found')) return null
            throw error
        }
    },
    updateProfile: async (data) => {
        const response = await apiService.post(`/v2/profiles/update-profile/`, data)
        return response
    },
}

export default AuthServices